<script setup lang="ts">

</script>

<template>
  <a href="https://dzen.ru/id/61e12c7c30e2ac3d0083cb17?utm_source=site" rel="nofollow" target="_blank" class="contacts__item">
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <rect class="path dzen back" width="44" height="44" rx="15" fill="#F2F3F6"></rect>
      <g clip-path="url(#clip0_514_13817)">
        <path d="M10 22C10 15.3726 15.3726 10 22 10C28.6274 10 34 15.3726 34 22C34 28.6274 28.6274 34 22 34C15.3726 34 10 28.6274 10 22Z" fill="#171E36"></path>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.7572 10C21.7005 14.9369 21.3776 17.6998 19.5387 19.5387C17.6998 21.3776 14.9369 21.7005 10 21.7572V22.4736C14.9369 22.5303 17.6998 22.8531 19.5387 24.692C21.3488 26.5021 21.69 29.2074 21.7543 34H22.4765C22.5408 29.2074 22.882 26.5021 24.692 24.692C26.5021 22.882 29.2074 22.5408 34 22.4765V21.7543C29.2074 21.69 26.5021 21.3488 24.692 19.5387C22.8531 17.6998 22.5303 14.9369 22.4736 10H21.7572Z" fill="white"></path>
      </g>
      <defs>
        <clipPath id="clip0_514_13817">
          <rect width="24" height="24" fill="white" transform="translate(10 10)"></rect>
        </clipPath>
      </defs>
    </svg>
  </a>
</template>
