<script setup lang="ts">

</script>

<template>
  <a href="https://www.youtube.com/channel/UCDymlzNnQQSJ3VfwrT4s7Hw?utm_source=site" rel="nofollow" target="_blank" class="contacts__item">
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <rect class="path youtube back" width="44" height="44" rx="15" fill="#F2F3F6"></rect>
      <path class="path youtube icon" d="M32.0617 16.9398C31.9422 16.4946 31.7078 16.0886 31.382 15.7624C31.0563 15.4363 30.6505 15.2014 30.2055 15.0813C28.5672 14.6406 22 14.6406 22 14.6406C22 14.6406 15.4328 14.6406 13.7945 15.0789C13.3493 15.1986 12.9433 15.4334 12.6175 15.7596C12.2917 16.0859 12.0574 16.4921 11.9383 16.9375C11.5 18.5781 11.5 22 11.5 22C11.5 22 11.5 25.4219 11.9383 27.0602C12.1797 27.9648 12.8922 28.6773 13.7945 28.9188C15.4328 29.3594 22 29.3594 22 29.3594C22 29.3594 28.5672 29.3594 30.2055 28.9188C31.1102 28.6773 31.8203 27.9648 32.0617 27.0602C32.5 25.4219 32.5 22 32.5 22C32.5 22 32.5 18.5781 32.0617 16.9398ZM19.9141 25.1406V18.8594L25.3516 21.9766L19.9141 25.1406Z" fill="#171E36"></path>
    </svg>
  </a>
</template>
