<script setup lang="ts">
  const { showHelpModal } = storeToRefs(useBookingStore());
</script>

<template>
  <div class="banner">
    <div class="left-block">
      <span class="banner-title-text">Поможем подобрать врача</span>
      <span class="banner-text">Оставьте заявку, и мы подберем<br> наиболее подходящего вам врача</span>
      <div class="button-container">
        <div class="banner-button" @click="showHelpModal = true">
          Подобрать врача
        </div>
      </div>
    </div>
    <div class="banner-img"></div>
  </div>
</template>

<style scoped lang="scss">
.banner {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 144px;
  background: linear-gradient(90deg, #C8A977 0.27%, #D7BA8B 54.99%);
  border-radius: 24px;
  box-shadow: 0px 4px 24px 0px rgba(172, 144, 99, 0.25);
}
.left-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 20px;
  padding-left: 20px;
}
.banner-img {
  width: 162px;
  height: 144px;
  border-radius: 24px;
  background: url(~/assets/img/hamburger-menu-image.png) lightgray 50% / cover no-repeat;
  @media (max-width: 418px) {
    display: none;
  }
}
.banner-title-text {
  color: white;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.banner-text {
  color: white;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.banner-button {
  width: fit-content;
  padding: 7px 12px;
  margin-top: 4px;
  border-radius: 22px;
  background-color: white;
  color: #262633;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  cursor: pointer;
}
</style>
