<script setup lang="ts">
  import VKLink from '~/UI/Contacts/VKLink.vue';
  import DzenLink from '~/UI/Contacts/DzenLink.vue';
  import YouTubeLink from '~/UI/Contacts/YouTubeLink.vue';
  import OkLink from '~/UI/Contacts/OkLink.vue';
  import TelegramLink from '~/UI/Contacts/TelegramLink.vue';

  const pageInfoStore = usePageInfoStore();

  const { currentRoute } = useRouter();

  const clickOnLogo = () => {
    if (currentRoute.value.path === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
    navigateTo('/');
  };
</script>

<template>
  <div class="lines">
    <div class="main-container footer-top-container">
      <IconLogo :font-controlled="false" filled class="logo" @click="clickOnLogo" />
      <div class="icons-container">
        <TelegramLink />
        <VKLink />
        <YouTubeLink />
        <DzenLink />
        <OkLink />
      </div>
      <div class="contacts-container">
        <div class="contact-container">
          <NuxtLink to="mailto:info@eastclinic.ru" class="contact-bold-text">
            info@eastclinic.ru
          </NuxtLink>
          <span class="contact-info-text">Почта</span>
        </div>
        <div class="contact-container">
          <NuxtLink :to="pageInfoStore.getLinkPhone" class="contact-bold-text">
            {{ pageInfoStore.getFormattedMainPhone }}
          </NuxtLink>
          <span class="contact-info-text">Мы на связи с 8:00 до 22:00</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.lines {
  border-top: 1px solid #E4E7EF;
  border-bottom: 1px solid #E4E7EF;
}
.footer-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding-top: 15px;
  padding-bottom: 19px;
  @media (max-width: 1023px) {
    flex-direction: column;
    padding-top: 32px;
    padding-bottom: 24px;
  }
}
.logo {
  width: 11.875rem;
  height: 1.5rem;
  cursor: pointer;
}
.icons-container {
  display: flex;
  align-items: center;
  gap: 10px;
  @media (max-width: 1023px) {
    order: 3;
  }
}
.contacts-container {
  display: flex;
  align-items: center;
  gap: 55px;
  @media (max-width: 1023px) {
    order: 2;
    gap: 32px;
    div:first-child {
      order: 2;
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 24px;
  }
}
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.contact-bold-text {
  color: #262633;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  text-decoration: none;
  &:hover {
    color: #2D7FF9 !important;
    cursor: pointer;
  }
}
.contact-info-text {
  color: #878FA2;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  @media (max-width: 1023px) {
    display: flex;
    align-self: center;
  }
}
</style>
