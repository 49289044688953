<script setup lang="ts">

</script>

<template>
  <a href="https://ok.ru/eastclinic.ru?utm_source=site" rel="nofollow" target="_blank" class="contacts__item">
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <rect class="path odnoclass back" width="44" height="44" rx="15" fill="#F2F3F6"></rect>
      <path class="path odnoclass icon" d="M21.9864 22.341C19.1614 22.341 16.8134 19.995 16.8134 17.219C16.8134 14.347 19.1614 12 21.9874 12C24.9094 12 27.1604 14.346 27.1604 17.219C27.1554 18.5821 26.6093 19.8873 25.6423 20.848C24.6753 21.8086 23.3664 22.346 22.0034 22.342L21.9864 22.341ZM21.9864 15.017C20.7904 15.017 19.8804 16.022 19.8804 17.22C19.8804 18.416 20.7904 19.326 21.9874 19.326C23.2324 19.326 24.0944 18.416 24.0944 17.22C24.0954 16.021 23.2324 15.017 21.9864 15.017ZM24.0464 26.603L26.9694 29.428C27.5444 30.049 27.5444 30.959 26.9694 31.534C26.3474 32.155 25.3884 32.155 24.9094 31.534L21.9874 28.661L19.1614 31.534C18.8744 31.821 18.4904 31.964 18.0584 31.964C17.7234 31.964 17.3404 31.82 17.0044 31.534C16.4294 30.959 16.4294 30.049 17.0044 29.427L19.9744 26.602C18.9018 26.2844 17.8729 25.8346 16.9114 25.263C16.1924 24.88 16.0494 23.923 16.4324 23.204C16.9114 22.486 17.7734 22.295 18.5404 22.774C19.579 23.4078 20.7722 23.7431 21.9889 23.7431C23.2056 23.7431 24.3988 23.4078 25.4374 22.774C26.2044 22.295 27.1134 22.486 27.5444 23.204C27.9764 23.923 27.7834 24.879 27.1124 25.263C26.2034 25.838 25.1494 26.269 24.0474 26.604L24.0464 26.603Z" fill="#171E36"></path>
    </svg>
  </a>
</template>
