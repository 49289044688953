<script setup lang="ts">

</script>

<template>
  <NuxtLink href="https://t.me/eastclinic?utm_source=site" rel="nofollow" target="_blank" class="contacts__item">
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
      <path d="M0 15C0 6.71573 6.71573 0 15 0H29C37.2843 0 44 6.71573 44 15V29C44 37.2843 37.2843 44 29 44H15C6.71573 44 0 37.2843 0 29V15Z" class="path telegram back"></path> <path d="M30.6646 13.7168L12.9346 20.5538C11.7246 21.0398 11.7316 21.7148 12.7126 22.0158L17.2646 23.4358L27.7966 16.7908C28.2946 16.4878 28.7496 16.6508 28.3756 16.9828L19.8426 24.6838H19.8406L19.8426 24.6848L19.5286 29.3768C19.9886 29.3768 20.1916 29.1658 20.4496 28.9168L22.6606 26.7668L27.2596 30.1638C28.1076 30.6308 28.7166 30.3908 28.9276 29.3788L31.9466 15.1508C32.2556 13.9118 31.4736 13.3508 30.6646 13.7168V13.7168Z" class="path telegram icon"></path>
    </svg>
  </NuxtLink>
</template>
